/* Tailwind CSS Imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap');




.loading-container {
  background-color: #010104; /* Dark background */
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 10rem;
  height: 10rem;
}

.shattered-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #010104; 

}

::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #433bff; /* Use accent color from Tailwind config */
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth color transition */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2f27ce; /* Darker color on hover */
}

::-webkit-scrollbar-track {
  background-color: #fbfbfe; /* Track color matching background */
  border-radius: 8px; /* Rounded corners */
}


